$(function(){
  // カテゴリーセレクトボックスのオプションを作成
  function appendOption(category){
    var html = `<option value="${category.id}" data-category="${category.id}">${category.name}</option>`;
    return html;
  }
  // 子カテゴリーの表示作成
  function appendChidrenBox(insertHTML){
    var childSelectHtml = '';
    childSelectHtml = `<div class='listing-select-wrapper__added' id= 'children_wrapper'>
                        <div class='listing-select-wrapper__box form-group my-2'>
                          <select class="listing-select-wrapper__box--select form-control col-md-4 offset-md-4 col-8 offset-2" id="child_category" name="search[category_children]", required: "required">
                            <option value="---" data-category="---">選択してください</option>
                            ${insertHTML}
                          <select>
                        </div>
                      </div>`;
    $('.listing-product-detail__category').append(childSelectHtml);
  }
  // 孫カテゴリーの表示作成
  function appendGrandchidrenBox(insertHTML){
    var grandchildSelectHtml = '';
    grandchildSelectHtml = `<div class='listing-select-wrapper__added' id= 'grandchildren_wrapper'>
                              <div class='listing-select-wrapper__box form-group my-2'>
                                <select class="listing-select-wrapper__box--select form-control col-md-4 offset-md-4 col-8 offset-2" id="grandchild_category" name="search[category_grandchildren]", required: "required">
                               <option value="---" data-category="---">選択してください</option>
                                  ${insertHTML}
                                </select>
                              </div>
                            </div>`;
    $('.listing-product-detail__category').append(grandchildSelectHtml);
  }
  // 親カテゴリー選択後のイベント
  $('#parent_category').on('change', function(){
    var parentCategory = document.getElementById('parent_category').value; //選択された親カテゴリーの名前を取得
    if (parentCategory != "---"){ //親カテゴリーが初期値でないことを確認
      // ユーザー用講座一覧画面のカテゴリ階層表示ロジック
      if (document.getElementById('for_user') !== null){
        $.ajax({
          url: 'get_category_children',
          type: 'GET',
          data: { parent_name: parentCategory },
          dataType: 'json'
        })
        .done(function(children){
;         $('#children_wrapper').remove(); //親が変更された時、子以下を削除するする
          $('#grandchildren_wrapper').remove();
          var insertHTML = '';
          children.forEach(function(child){
            insertHTML += appendOption(child);
          });
          appendChidrenBox(insertHTML);
        })
      // 管理者用の動画一覧画面、動画新規作成画面のカテゴリ階層表示ロジック
      }else{
        // 動画編集画面用
        if(document.getElementById('for_edit') !== null ){
          $.ajax({
            url: 'get_category_children',
            type: 'GET',
            data: { parent_name: parentCategory },
            dataType: 'json'
          })
          .done(function(children){
;           $('#children_wrapper').remove(); //親が変更された時、子以下を削除するする
            $('#grandchildren_wrapper').remove();
            var insertHTML = '';
            children.forEach(function(child){
              insertHTML += appendOption(child);
            });
            appendChidrenBox(insertHTML);
          })
          .fail(function(){
            alert('カテゴリー取得に失敗しました');
          })
        // 動画編集画面 以外用
        }else{
          $.ajax({
            url: 'videos/get_category_children',
            type: 'GET',
            data: { parent_name: parentCategory },
            dataType: 'json'
          })
          .done(function(children){
;           $('#children_wrapper').remove(); //親が変更された時、子以下を削除するする
            $('#grandchildren_wrapper').remove();
            var insertHTML = '';
            children.forEach(function(child){
              insertHTML += appendOption(child);
            });
            appendChidrenBox(insertHTML);
          })
          .fail(function(){
            alert('カテゴリー取得に失敗しました');
          })
        }
      }
    }else{
      $('#children_wrapper').remove(); //親カテゴリーが初期値になった時、子以下を削除するする
      $('#grandchildren_wrapper').remove();
    }
  });
  // 子カテゴリー選択後のイベント
  $('.listing-product-detail__category').on('change', '#child_category', function(){
    var childId = $('#child_category option:selected').data('category'); //選択された子カテゴリーのidを取得
    if (childId != "---"){ //子カテゴリーが初期値でないことを確認
      // ユーザー用講座一覧画面のカテゴリ階層表示ロジック
      if (document.getElementById('for_user') !== null){
        $.ajax({
          url: 'get_category_grandchildren',
          type: 'GET',
          data: { child_id: childId },
          dataType: 'json'
        })
        .done(function(grandchildren){
          if (grandchildren.length != 0) {
            $('#grandchildren_wrapper').remove(); //子が変更された時、孫以下を削除するする
            var insertHTML = '';
            grandchildren.forEach(function(grandchild){
              insertHTML += appendOption(grandchild);
            });
            appendGrandchidrenBox(insertHTML);
          }
        })
        .fail(function(){
          $.ajax({
          url: 'get_category_grandchildren',
          type: 'GET',
          data: { child_id: childId },
          dataType: 'json'
        })
        .done(function(grandchildren){
          if (grandchildren.length != 0) {
            $('#grandchildren_wrapper').remove(); //子が変更された時、孫以下を削除するする
            var insertHTML = '';
            grandchildren.forEach(function(grandchild){
              insertHTML += appendOption(grandchild);
            });
            appendGrandchidrenBox(insertHTML);
          }
        })
          alert('カテゴリー取得に失敗しました');
        })
      // 管理者用動画一覧画面のカテゴリ階層表示ロジック
      }else{
        // 動画編集画面用
        if(document.getElementById('for_edit') !== null ){
          if (childId === undefined) {
            childId = document.getElementById('child_category').value
          };
          $.ajax({
            url: 'videos/get_category_grandchildren',
            type: 'GET',
            data: { child_id: childId },
            dataType: 'json'
          })
          .done(function(grandchildren){
            if (grandchildren.length != 0) {
              $('#grandchildren_wrapper').remove(); //子が変更された時、孫以下を削除するする
              var insertHTML = '';
              grandchildren.forEach(function(grandchild){
                insertHTML += appendOption(grandchild);
              });
              appendGrandchidrenBox(insertHTML);
            }
          })
          .fail(function(){
            alert('カテゴリー取得に失敗しました');
          })
        // 動画新規登録画面用
        }else{
          $.ajax({
            url: 'videos/get_category_grandchildren',
            type: 'GET',
            data: { child_id: childId },
            dataType: 'json'
          })
          .done(function(grandchildren){
            if (grandchildren.length != 0) {
              $('#grandchildren_wrapper').remove(); //子が変更された時、孫以下を削除するする
              var insertHTML = '';
              grandchildren.forEach(function(grandchild){
                insertHTML += appendOption(grandchild);
              });
              appendGrandchidrenBox(insertHTML);
            }
          })
          .fail(function(){
            $.ajax({
              url: 'get_category_grandchildren',
              type: 'GET',
              data: { child_id: childId },
              dataType: 'json'
            })
            .done(function(grandchildren){
              if (grandchildren.length != 0) {
                $('#grandchildren_wrapper').remove(); //子が変更された時、孫以下を削除するする
              var insertHTML = '';
              grandchildren.forEach(function(grandchild){
                insertHTML += appendOption(grandchild);
              });
              appendGrandchidrenBox(insertHTML);
            }
          })
            // alert('カテゴリー取得に失敗しました');
          })
        }
      }
    }else{
      $('#grandchildren_wrapper').remove(); //子カテゴリーが初期値になった時、孫以下を削除する
    }
  });
});
